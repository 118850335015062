import { Provider } from '@angular/core';

import { UserDataAccessApiProviders } from './+api/api.providers';
import { UserDataAccessStateProviders } from './+state/state.providers';

export const UserDataAccessProviders: Provider = [
    //
    UserDataAccessApiProviders,
    UserDataAccessStateProviders,
];
