import { Provider } from '@angular/core';

import { AuthenticationResource } from '@portal/shared/utils';

import { LoginController } from '@portal/user/utils';

export const UserDataAccessApiProviders: Provider = [
    //
    AuthenticationResource,
    LoginController,
];
