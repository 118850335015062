import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { JwtResponseDto } from '@portal/shared/utils';

export const FeatureActions = createActionGroup({
    source: 'User',
    events: {
        persistUserAuth: props<{ jwt: JwtResponseDto }>(),
        persistUserAuthSuccess: emptyProps(),
        purgeUserAuth: emptyProps(),
        purgeUserAuthSuccess: emptyProps(),
    },
});
