import { Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { FeatureEffects } from './feature.effects';
import { FeatureEventBus } from './feature.event-bus';
import { FeatureFacade } from './feature.facade';
import { FEATURE_KEY } from './feature.keys';
import { featureReducers } from './feature.reducers';

export const UserDataAccessStateProviders: Provider = [
    provideState(FEATURE_KEY.FEATURE, featureReducers),
    provideEffects([FeatureEffects]),
    FeatureFacade,
    FeatureEventBus,
];
