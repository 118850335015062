import { inject } from '@angular/core';
import { Actions, createEffect, FunctionalEffect, ofType } from '@ngrx/effects';
import { iif, map, of, switchMap, tap } from 'rxjs';

import { AUTH_MANAGER, AUTH_NAVIGATOR, JwtResponseDto } from '@portal/shared/utils';

import { FeatureActions } from './feature.actions';

const initialize: FunctionalEffect = createEffect(
    () =>
        inject(AUTH_MANAGER)
            .initializeJwt()
            .pipe(
                switchMap((jwt: JwtResponseDto | null) =>
                    iif(() => jwt === null, of(FeatureActions.purgeUserAuth()), of(FeatureActions.persistUserAuth({ jwt: jwt! })))
                )
            ),
    { functional: true }
);

const persistUserAuth: FunctionalEffect = createEffect(
    (actions = inject(Actions), authManager = inject(AUTH_MANAGER)) =>
        actions.pipe(
            ofType(FeatureActions.persistUserAuth.type),
            tap(({ jwt }: ReturnType<typeof FeatureActions.persistUserAuth>) => {
                authManager.setJwt(jwt);
            }),
            map(({ jwt }: ReturnType<typeof FeatureActions.persistUserAuth>) => FeatureActions.persistUserAuthSuccess())
        ),
    { functional: true }
);

const persistUserAuthSuccess: FunctionalEffect = createEffect(
    (actions = inject(Actions), authNavigator = inject(AUTH_NAVIGATOR)) =>
        actions.pipe(
            ofType(FeatureActions.persistUserAuthSuccess.type),
            tap(() => {
                void authNavigator.loginNavigate();
            })
        ),
    { functional: true, dispatch: false }
);

const purgeUserAuth: FunctionalEffect = createEffect(
    (actions = inject(Actions), authManager = inject(AUTH_MANAGER)) =>
        actions.pipe(
            ofType(FeatureActions.purgeUserAuth.type),
            tap(() => {
                authManager.resetJwt();
            }),
            map(() => FeatureActions.purgeUserAuthSuccess())
        ),
    { functional: true }
);

const purgeUserAuthSuccess: FunctionalEffect = createEffect(
    (actions = inject(Actions), authNavigator = inject(AUTH_NAVIGATOR)) =>
        actions.pipe(
            ofType(FeatureActions.purgeUserAuthSuccess.type),
            tap(() => {
                void authNavigator.logoutNavigate();
            })
        ),
    { functional: true, dispatch: false }
);

export const FeatureEffects = {
    initialize,
    persistUserAuth,
    persistUserAuthSuccess,
    purgeUserAuth,
    purgeUserAuthSuccess,
};
