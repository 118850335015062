import { createReducer, on } from '@ngrx/store';

import { UserListActions } from './user-list.actions';
import { initialUserListState } from './user-list.state';

export const userListReducer = createReducer(
    initialUserListState,
    on(UserListActions.load, (state) => ({
        ...state,
        loading: true,
    }))
);
